.login_form__submit {
  display: flex;
  justify-content: flex-end;
}



.login_form__option_forgot {
  color: white;
  font-style: italic;
  margin-top: 30px;
  text-align: right;
  cursor: pointer;
}
.feedback {
  left: 15px;
}

.form_error {
  margin-top: 15px;
  text-align: center;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
