.searchForPatient_container {
  padding-left: 0px;
  height: auto;
  padding-right: 0px;
}

.searchForPatient_container h1 {
  font-weight: 350;
  font-size: 30px;
}


.patient__block_button {
  min-height: 38px;
  height: auto;
  min-width: 250px;
  margin-bottom: 0;
}
